import { useState, useEffect, useContext } from 'react'
import { lang, gotoURL, gotoLink } from '../lang'
import ThemeContext from '../../context/ThemeContext';
import { AiOutlineArrowLeft } from "react-icons/ai";

export default function Nav(props) {
    const selected_menu= props.selected_menu;
    const menu = props.menu;
    const layout = props.styles;
    const level = props.level;
    const menu_level = props.menu_level;
    const setMenuLevel = props.setMenuLevel;
    const { language, page } = useContext(ThemeContext);


    useEffect(()=>{
        // console.log("props",props);
        // if(selected_menu[selected_menu.length-1]){
        //     console.log("all menu",menu);
        // }
    },[selected_menu]);

    const handleClickBack = () =>{
        let current = selected_menu;
        current.pop();
        props.setSelectMenu(ele=>[...current]);
        if(current.length===0){
            props.setSelectMenu(ele=>[1]);
        }
        setMenuLevel(level-1);
    }

    const gotoPage = (menu) =>{
        let url = menu.link;
        if(menu.link===undefined){
            url ='/';
        }
        // console.log(url);
        if(menu.target==="_blank"){
            gotoLink(url);
        }else{
            gotoURL(url);
        }
    }
    return (
        (menu!==undefined&&menu.child.length>0&&menu_level===level) ?
        <>
        <div className={layout.menu_toolbar}>
            <button onClick={e=>handleClickBack()}><AiOutlineArrowLeft /> {lang("กลับ","Back","返回")}</button>
            <h4>{lang(menu.menu_th,menu.menu_en,menu.menu_cn)}</h4>
        </div>
        <ul className={`${layout.menu_list}`}>
        {menu.child.map((element,index)=>
            <li className={selected_menu.includes(parseInt(element.menu_id))?layout.active:''} key={'sub-menu-'+level+'-'+index}>
                <button onClick={e=>(element.child.length>0)?props.handleSelectMenu(element,level):gotoPage(menu)} className={(element.child.length>0)?layout.plus:''}>{lang(element.menu_th,element.menu_en,element.menu_cn)}</button>
                <div className={`${layout.sub_menu_list}`}>
                {element.child.map((item,index)=>
                    (selected_menu.includes(parseInt(element.menu_id)))?
                    <div className={(selected_menu[selected_menu.length-1]===item.menu_id)?layout.active:''} key={'sub-menu-'+level+'-'+index}>
                    <button onClick={e=>gotoPage(item)} className={(item.child.length>0)?layout.plus:''}>- {lang(item.menu_th,item.menu_en,item.menu_cn)}</button></div>
                    :''
                )}
                </div>
            </li>
        )}
        </ul>
        </>
        :''
    )
}
