import { useState, useEffect } from "react"
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import { lang, img_url, gotoURL } from '../lang'
import api from '../api'

export default function Souvenir(props) {
    const styles = props.styles;
    SwiperCore.use([Autoplay]); // start autoplay
    const [articles, setArticles] = useState([]);
	const navigate = useNavigate();

    useEffect(()=>{
        const fetchInit = async () =>{
            let show_total = 15;
            if(window.innerWidth<640){
                show_total= 8;
            }
            try {
                const response = await api.get('/souvenir_home/'+show_total);
                setArticles(response.data.results);
                // console.log(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    return (
        <>
        <div className={styles["blocks"]}>
          <div className={styles["title"]}><span>discover</span></div>
          <div className={styles["sub-title"]}>
            <h1>{lang("ของที่ระลึก","Souvenir",'纪念品')}</h1>
            <button className={styles["view-more"]} onClick={() => gotoURL('/souvenir')}>{lang('ดูเพิ่มเติม','View more','查看更多')} </button>
          </div>
            <div className={styles["souvenir-container"]}>
                {articles.map((item, index) => (
                    <img src={img_url+item.img_url} alt={item.detail} key={'souv-'+index}/>
                ))}
            </div>
        </div>
        <div className={styles["souvenir-footer"]}>
        </div>
        </>
    )
}
