import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import { lang, img_url } from '../components/lang'
import { useLocation } from "react-router-dom";
import api from '../components/api'
import styles from '../assets/css/ebook.module.css';
import SouvenirContext from '../context/SouvenirContext';

const PersonMenu = lazy(() => import ('../components/Template/PersonMenu'))
const Nav = lazy(() => import ('../components/Template/Nav'))
const Lists = lazy(() => import ('../components/Resource/PersonLists'))
const Detail = lazy(() => import ('../components/Resource/PersonDetail'))

export default function Person(props) {
    // const [init, setInit] = useState([]);
    // const [book_type, setBookType] = useState('all');
    const [nav_data, setNavData] = useState([]);
    const [filterResources, setFilterResources] = useState([]);
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    let keyword = (params.get('keyword'))?params.get('keyword'):'';
    let itemid = (params.get('id'))?params.get('id'):0;
    const [filter, setFilter] = useState(keyword);
    const type_params = useParams();
    const [type, setType] = useState(1);
    const [person_type, setPersonType] = useState("doctor");
    const [title, setTitle] = useState('ดุษฎีบัญทิตกิตติมศักดิ์');
    const [menu_id, setMenuID] = useState(0);
    const [item_id, setItemID] = useState(itemid);
    const [resource, setResource] = useState({});
    const [resources, setResources] = useState([]);
    const [souvenir_type, setSouvenirType] = useState(0);
    const [country, setCountry] = useState(0);
    const [institute, setInstitute] = useState(0);
    const [year, setYear] = useState(0);
    const location = useLocation();
    let path = location.pathname.split("/");

    useEffect(()=>{
        let props_type = (props.type)?props.type:1;
        if(parseInt(props_type)>2){
            let pres_type = (type_params.typeId)?type_params.typeId:1;
            if(parseInt(pres_type)===1){
                props_type = 3;
                setTitle('นายกสภามหาวิทยาลัย');
            }else if(parseInt(pres_type)===2){
                props_type = 4;
                setTitle('อธิการบดีมหาวิทยาลัย');
            }else{
                props_type = 5;
                setTitle('บุคคลผู้มีส่วนเกี่ยวข้องกับมช.');
            }
        }
        // console.log(type_params.typeId,props_type);
        setType(props_type);
        let person_type = (props.person_type)?props.person_type:"doctor";
        setPersonType(person_type);

        let itemId = (type_params.itemId)?type_params.itemId:itemid;
        setItemID(itemId);
        // console.log(itemId);
        const fetchInit = async () =>{
            if(itemId!==0){
              try {
                if(props_type>2){
                    const response = await api.get('/president/'+itemId);
                    setResource(response.data.results);
                }else{
                  const response = await api.get('/doctor/'+itemId);
                  setResource(response.data.results);
                  if(props_type===1){
                    setTitle('ดุษฎีบัญทิตกิตติมศักดิ์');
                  }else{
                    setTitle('ศาสตราจารย์เกียรติคุณ');
                  }
                }
              } catch (err) {
                  if(err.response){
                      //Not in 202 response range
                      console.log(err.response.data);
                      console.log(err.response.headers);
                      console.log(err.response.status);
                  } else {
                      console.log(`Error: ${err.message}`);
                  }
              }
            }
            try {
              const response = await api.get('/menu?type=left-menu');
              setNavData(response.data.results);
              response.data.results.forEach(element => {
                  if(('/'+path[1])===element.link){
                      setTitle(lang(element.menu_th,element.menu_en,element.menu_cn));
                      setMenuID(element.id);
                  }
                  if(props_type>2&&('/'+path[1]+'/'+path[2])===element.link){
                    setTitle(lang(element.menu_th,element.menu_en,element.menu_cn));
                    setMenuID(element.id);
                  }
              });
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
            try {
                const response = await api.get('/person?type='+props_type+'&year='+year);
                setResources(current => response.data.results);
                setFilterResources(response.data.results);
                // console.log(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
        window.addEventListener("scroll", reveal);
        reveal();

    },[]);
    
    useEffect(()=>{
        let props_type = (props.type)?props.type:1;
        setType(props_type);
        const fetchData = async () =>{
            try {
                const response = await api.get('/person?type='+props_type+'&year='+year);
                setResources(current => response.data.results);
                setFilterResources(current => response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchData();
    },[year,props.type]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    return (
        <SouvenirContext.Provider
          value={{ souvenir_type, country, institute }}
        >   
        <div className={styles["resource-container"]}>
          <div className={styles["ebook-container"]}>
              <Nav styles={styles} nav_data={nav_data} />
              <div className={styles["content"]+" reveal"}>
                  <PersonMenu person_type={person_type} item_id={item_id} title={title} year={year} setYear={setYear} type={type} setType={setType} right={true}/>
                  <div className={styles["content-container"]}>
                  {(item_id===0)?(
                    <Suspense fallback={<h1>Loading Resource</h1>}>
                        <Lists styles={styles} data={filterResources} title={title} type={type} setItemID={setItemID} setResource={setResource}/>
                    </Suspense>
                  ):(
                    <Suspense fallback={<h1>Loading Item</h1>}>
                        <Detail styles={styles} person_type={person_type} title={title} data={resource} item_id={item_id} type={type} setResource={setResource}/>
                    </Suspense>
                  )}
                  </div>
              </div>
            </div>
        </div>
    </SouvenirContext.Provider>
    )
}