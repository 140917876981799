import { useState, useEffect, useContext } from 'react'
import { lang, img_url, gotoURL, gotoLink, check_brightness } from '../components/lang'
import ThemeContext from '../context/ThemeContext';
import api from '../components/api'
import Slide from '../components/Homepage/Slide'
import News from '../components/Homepage/News'
import Souvenir from '../components/Homepage/Souvenir'
import Exhibitions from '../components/Homepage/Exhibitions'
import classes from '../assets/css/module/home.module.css';

export default function Home() {
  const [article, setArticle] = useState([]);
  const [news, setNews] = useState([]);
  const [config, setConfig] = useState({});
  const { language, page, init } = useContext(ThemeContext);

  useEffect(()=>{
    const fetchInit = async () =>{
        try {
          const response = await api.get('/article/1');
          setArticle(response.data.results);
          // console.log(response.data.results);
          const home_config = JSON.parse(response.data.results.detail);
          setConfig(home_config);
          // console.log(home_config);
          // console.log(response.data.results.detail);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
      try {
        const response = await api.get('/article/category/2:3');
        setNews(response.data.results);
      } catch (err) {
          if(err.response){
              //Not in 202 response range
              console.log(err.response.data);
              console.log(err.response.headers);
              console.log(err.response.status);
          } else {
              console.log(`Error: ${err.message}`);
          }
      }
    }
    fetchInit();
  },[]);

  return (
    <>
      <div className={classes["homepage"]}>
            <div className={classes["main-slide"]}>
                <Slide article={article} classes={classes} />
            </div>
      </div>
      <section className={classes["about-us"]}>
          {(article.id)?
          <article className={classes["blocks"]}>
              <h5 className={classes["title"]}><span>{lang(article.title_th,article.title_en,article.title_cn)}</span></h5>
              <p>
                  {lang(article.info_th,article.info_en,article.info_cn)}
                  <button className={classes["view-more"]} onClick={() => gotoURL('/about')}>{lang('รายละเอียด','More Details','细节')} </button>
              </p>
          </article>
          :''}
      </section>
      <div className={classes["parallax"]}>
        <picture>
          {(article.id)?(
            <img src={img_url+article.cover_img} width="1920" height="470" alt={lang(article.title_th,article.title_en,article.title_cn)} />
          ):''}
        </picture>
      </div>
      <section className={classes["activity"]} style={{'--bg-color':config.activity_color,'--txt-color':(check_brightness(config.activity_color))?'#000':'#fff'}}>
          {(article.id)?
          <News styles={classes} />
          :''}
      </section>
      <section className={classes["souvenir"]} style={{'--bg-color':config.book_color,'--txt-color':(check_brightness(config.book_color))?'#000':'#fff'}}>
          {(article.id)?
          <Souvenir styles={classes} />
          :''}
      </section>
      <section className={classes["exhibition"]} style={{'--bg-color':config.calendar_color,'--txt-color':(check_brightness(config.calendar_color))?'#000':'#fff'}}>
          {(article.id)?
          <Exhibitions styles={classes} />
          :''}
      </section>
    </>
  )
}
