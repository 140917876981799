import { useState, useEffect, lazy, Suspense, useContext } from "react"
import { useParams } from 'react-router'
import styles from '../assets/css/module/article.module.css'
import Create from '../components/Reservation/Form'
import Nav from "../components/Template/Nav";

export default function Reservation() {
  return (
    <div className={styles["article-container"]}>
          <div className={"content reveal"}>
              <Create styles={styles} />
          </div>
    </div>
  )
}
