import axios from 'axios';
const client = "faculty_nurse";
const token = encodeURIComponent(window.btoa(client));
const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': 'Bearer '+token.toString()
}

export default axios.create({
  baseURL: `https://cmumuseum.library.cmu.ac.th/api/`,
  // baseURL: `http://localhost/cmumuseum/api/`,
  headers: headers
});