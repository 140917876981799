import { useState, useEffect } from "react"
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { lang, img_url } from '../lang'
import api from '../api'


SwiperCore.use([Navigation, Pagination, A11y]);
export default function Exhibitions(props) {
    const styles = props.styles;
    SwiperCore.use([Autoplay]); // start autoplay
    const [articles, setArticles] = useState([]);
	const navigate = useNavigate();

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/article/home/2');
                setArticles(response.data.results);
                // console.log(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    return (
        <div className={styles["blocks"]}>
          <div className={styles["title"]}><span>don't miss</span></div>
          <div className={styles["sub-title"]}>
            <h1>{lang("นิทรรศการ","Exhibitions",'展览')}</h1>
            <button className={styles["view-more"]} onClick={() => navigate('/about')}>{lang('ดูเพิ่มเติม','View more','查看更多')} </button>
          </div>
            <div className={styles["exhibition-container"]}>
                {/* group items  */}
                <Swiper
                    slidesPerView={4}
                    centeredSlides={false}
                    spaceBetween={40}
                    speed={900}
                    grabCursor={true}
                    breakpoints={{
                      // when window width is >= 320px
                      320: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 640px
                      800: {
                        slidesPerView: 3,
                      },
                      1280 : {
                        slidesPerView: 4,
                      },
                      1536 : {
                        slidesPerView: 4,
                      }
                    }}
                    pagination={{
                        el: ".exhibition-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-exhibition-swiper-next",
                      prevEl: ".art-exhibition-swiper-prev"
                    }}
                    modules={[Pagination]}
                    effect={"fade"}
                    className="exhibition-swiper"
                >
                    {articles.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <div className={styles["card"]+" group"}>
                                <div className={styles["image"]} onClick={() => navigate(item.url)}>
                                    <img src={img_url+item.cover_img} alt={lang(item.title_th,item.title_en,item.title_cn)} />
                                </div>

                                <div className={styles["info"]}>
                                    <div className={styles["slide-title"]} onClick={() => navigate(item.url)}><h4>{lang(item.title_th,item.title_en)}</h4></div>
                                    <div className={styles["detail"]} dangerouslySetInnerHTML={{__html: lang(item.info_th,item.info_en,item.info_cn)}}></div>
                                </div>
                                <span className={styles["card-end"]}></span>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SwiperNavigation section="exhibition" />
            </div>
        </div>
    )
}
