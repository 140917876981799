import { useState, useEffect, useContext } from 'react'
import { lang, img_url, getDate } from '../lang'
import { useNavigate, useLocation } from 'react-router-dom';
import { BsLine } from "react-icons/bs";
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaYoutube, FaTiktok, FaAlignRight, FaChevronDown } from 'react-icons/fa';
import api from '../../components/api'
import th_icon from '../../assets/th.jpg';
import en_icon from '../../assets/en.jpg';
import cn_icon from '../../assets/ch.png';
import logo from '../../assets/logo.png';
import menu_classes from '../../assets/css/module/menu.module.css';

export default function NavMenu(props) {
    const {language, setLanguage, init, layout, header_color, font_size, setFont} = props;
    const [mobile_menu, setMobileMenu] = useState(false);
    const [menus, setMenus] = useState([]);
    const location = useLocation();
    let path = location.pathname.split("/");

    const fetchInit = async () =>{
        const response = await api.get('/menu?type=%main-menu%&order=parent_menu_id,order asc');
          
        let menu = [];
        let menu_index = [];
        response.data.results.forEach(item => {
            if(!menu_index.includes(item.id)){
              menu_index.push(item.id);
            }
            let menu_active = false;
            if(location.pathname===item.link){
              menu_active = true;
              if(menu[menu_index.indexOf(item.parent_menu_id)]){
                menu[menu_index.indexOf(item.parent_menu_id)].active = true;
              }
            }
            if(item.parent_menu_id==='0'){
                let path = location.pathname.split("/");
                let show = 0;
                if (location.pathname===item.link||item.link === '/'+path[1]) {
                    show = 1;
                }
                // console.log(path);
                menu[menu_index.indexOf(item.id)] = {
                    child: [],
                    menu_id: item.id,
                    menu_th: item.menu_th,
                    menu_en: item.menu_en,
                    menu_cn: item.menu_cn,
                    link: item.link,
                    parent_menu_id: item.parent_menu_id,
                    menu_type: item.menu_type,
                    order: item.order,
                    target: item.target,
                    active: menu_active,
                    show: show
                }
            }else{
                let set_menu = false;
                menu.forEach(element => {
                    if(element.menu_id===item.parent_menu_id){
                        let tmp = {
                                    child: [],
                                    menu_id: item.id,
                                    menu_th: item.menu_th,
                                    menu_en: item.menu_en,
                                    menu_cn: item.menu_cn,
                                    link: item.link,
                                    parent_menu_id: item.parent_menu_id,
                                    menu_type: item.menu_type,
                                    order: item.order,
                                    target: item.target,
                                    active: menu_active,
                                    show: 0,
                                };
                        // console.log(item.id);
                        menu[menu_index.indexOf(item.parent_menu_id)].child.push(tmp);
                        set_menu = true;
                    }
                });
                if(set_menu===false){
                    menu.forEach((element,index) => {
                        element.child.forEach((celement,cindex) => {
                            if(item.parent_menu_id===celement.menu_id){
                                let tmp = {
                                            child: [],
                                            menu_id: item.id,
                                            menu_th: item.menu_th,
                                            menu_en: item.menu_en,
                                            menu_cn: item.menu_cn,
                                            link: item.link,
                                            parent_menu_id: item.parent_menu_id,
                                            menu_type: item.menu_type,
                                            order: item.order,
                                            target: item.target,
                                            active: menu_active,
                                            show: menu_active,
                                        };
                                // console.log(item.id);
                                menu[index].child[cindex].child.push(tmp);
                                set_menu = true;
                            }
                        });
                    });
                }   
                if(set_menu===false){
                    console.log("Error Can't set menu",item);
                }
            }
            // if(item.parent_menu_id!=='0'){
            //     let tmp = {
            //         child: [],
            //         menu_id: item.id,
            //         menu_th: item.menu_th,
            //         menu_en: item.menu_en,
            //         menu_cn: item.menu_cn,
            //         link: item.link,
            //         parent_menu_id: item.parent_menu_id,
            //         menu_type: item.menu_type,
            //         order: item.order,
            //         target: item.target,
            //         active: menu_active,
            //         show: menu_active,
            //     };
            //     // console.log(item.id);
            //     menu[menu_index.indexOf(item.parent_menu_id)].child.push(tmp);
            // }
        });
        // console.log(menu);
        setMenus(menu);
    }

    useEffect(()=>{
        fetchInit();
    },[]);

    const setActive = (e,menu_id) => {
        // console.log(menu_id);
        
        let tmp = menus;
        tmp.forEach(item => {
              if(item.menu_id === menu_id){
                  item.active = !item.active;
                  item.show = (item.show===0)?1:0;
              } 
        });
        setMenus([...tmp]);
        // setChkMenu(menu_id);
    }
    return (
        <div className={layout["menu"]}>
            <div className={layout["mobile-menu"]}>
                <button onClick={e => setMobileMenu(true)} className={layout["mobile-nav-open"]}>
                <FaAlignRight/>
                </button>
                <div className={mobile_menu?layout["mobile-nav"]+' '+layout['active']:layout["mobile-nav"]} style={{"background":(mobile_menu)?"#fff":header_color}}>
                <ul className={layout["top"]}>
                    <li>
                    <a href={'#close'} onClick={e => setMobileMenu(false)}>X</a>
                    </li>
                    <li className={layout["mobile-menu-language"]+' '+layout['group']}>
                        {language==="th"?(<div><img src={th_icon} alt="ภาษาไทย" width="36" height="15" /> ไทย <FaChevronDown /></div>):''}
                        {language==="en"?(<div><img src={en_icon} alt="ENGLISH" width="36" height="15" /> Eng <FaChevronDown /></div>):''}
                        {language==="cn"?(<div><img src={en_icon} alt="汉语" width="36" height="15" /> 汉语 <FaChevronDown /></div>):''}
                        <div className={layout["submenu"]+" invisible group-hover:visible"}>
                        <ul className="">
                            <li><button onClick={()=>setLanguage("th")}><img src={th_icon} alt="ภาษาไทย" width="36" height="15" /> ภาษาไทย</button></li>
                            <li><button onClick={()=>setLanguage("en")}><img src={en_icon} alt="ENGLISH" width="36" height="15" /> ENGLISH</button></li>
                            <li><button onClick={()=>setLanguage("cn")}><img src={cn_icon} alt="汉语" width="36" height="15" /> 汉语</button></li>
                        </ul>
                        </div>
                    </li>
                </ul>
                <ul className={layout["ul-menu-nav"]}>
                {menus.map((menu, key) =>(
                    <li key={"mobile-menu"+key} className={menu.active?layout["active"]+' '+layout['group']:layout['group']}>
                         {(menu.link)?(
                            <a href={menu.link} target={menu.target}>
                                {lang(menu.menu_th,menu.menu_en,menu.menu_cn)}
                            </a>
                         ):( 
                            <p onClick={e=>setActive(e,menu.menu_id)}>
                                {lang(menu.menu_th,menu.menu_en,menu.menu_cn)}
                            </p> 
                        )}
                    {
                        (menu.child.length>0&&menu.show===1)?(
                        <div className={`${layout["submenu"]}`}>
                            <ul>
                            {menu.child.map((sub_menu, sub_menu_key) =>(
                            <li key={"mobile-sub_menu"+sub_menu_key} className={sub_menu.active?layout["active"]:''}>
                                    <a href={sub_menu.link} target={sub_menu.target}>{lang(sub_menu.menu_th,sub_menu.menu_en,sub_menu.menu_cn)}</a>
                                    <ul className={layout["menu-link"]}>
                                    {sub_menu.child.map((sub_menu_child, sub_menu_child_key) =>(
                                        <li key={"sub_menu_child_"+sub_menu_child_key}>
                                            <a href={process.env.PUBLIC_URL + sub_menu_child.link} target={sub_menu.target}>
                                                {lang(sub_menu_child.menu_th,sub_menu_child.menu_en,sub_menu_child.menu_cn)}
                                            </a>
                                        </li>
                                    ))}
                                    </ul>
                            </li>
                            ))}
                            </ul>
                        </div>
                        ):""
                    }
                    </li>
                ))}
                </ul>
                <ul className={layout["ul-menu-info"]}>
                    <li className={layout["info"]}>
                    <a href={process.env.PUBLIC_URL + "/"} ><img src={logo} alt={lang(init.name_th,init.name_en)} width="102" height="96.25"/></a>
                    <h3>{lang(init.name_th,init.name_en)}</h3>
                    </li>
                    <li className={layout["open"]}>
                    <p>{lang('เวลาทำการ','OPENING HOUR')}: </p>
                    <p>{lang(init.open_info_th,init.open_info_en)}</p>
                    </li>
                    <li className={layout["font-tool"]}>
                    <p>{lang('ขนาดตัวอักษร','Font Size')}: </p>
                    <ul className="font-size">
                        <li className={font_size==="small"?layout['active']:''}><button onClick={()=>setFont("small")}>ก</button></li>
                        <li className={font_size==="normal"?layout['active']:''}><button onClick={()=>setFont("normal")}>ก</button></li>
                        <li className={font_size==="large"?layout['active']:''}><button onClick={()=>setFont("large")}>ก</button></li>
                    </ul>
                    </li>
                    <li className={layout["social"]}>
                    <ul>
                        <li><a href={init.facebook} target="_blank" rel="noreferrer" className={layout["rounded-btn"]}><FaFacebookF /></a></li>
                        <li><a href={init.line} target="_blank" rel="noreferrer" className={layout["rounded-btn"]}><BsLine /></a></li>
                        <li><a href={init.twitter} target="_blank" rel="noreferrer" className={layout["rounded-btn"]}><FaTwitter /></a></li>
                        <li><a href={init.tiktok} target="_blank" rel="noreferrer" className={layout["rounded-btn"]}><FaTiktok /></a></li>
                        <li><a href={init.youtube} target="_blank" rel="noreferrer" className={layout["rounded-btn"]}><FaYoutube /></a></li>
                        <li><a href={`mailto:`+init.email} className={layout["rounded-btn"]}><FaEnvelope /></a></li>
                        <li><a href={`tel:`+init.phone} className={layout["rounded-btn"]}><FaPhoneAlt /></a></li>
                    </ul>
                    </li>
                </ul>
                </div>
            </div>
            <div className={layout["desktop-menu"]}>
                <ul className={menu_classes["navbar"]}>
                { menus.map((menu, key) =>(
                    <li key={"menu"+key} className={menu.active?"group active":"group"}>
                    <a href={process.env.PUBLIC_URL + menu.link} target={menu.target}>{lang(menu.menu_th,menu.menu_en,menu.menu_cn)}</a>
                    {
                    (menu.child.length>0)?(
                        <div className={layout["submenu"]+" invisible group-hover:visible "+menu_classes["dropdown-menu"]+` ${(menu.child.length<=4)?menu_classes["one-row-1"]:''}`}>
                            <div className={menu_classes["dropdown-menu-wrapper"]}>
                                <div className={(menu.child.length>4)?menu_classes["dropdown-menu-grid-4"]:menu_classes["dropdown-menu-grid-1"]}>
                                    {menu.child.map((sub_menu, sub_menu_key) =>(
                                        <div className="dropdown-menu-detail" key={"sub_menu"+sub_menu_key}>
                                            {(sub_menu.child.length>0)?(
                                                <>
                                                    <p className={menu_classes["menu-header"]} key={"sub_menu"+sub_menu_key}>
                                                        {(sub_menu.link)?(
                                                        <a href={process.env.PUBLIC_URL + sub_menu.link} target={sub_menu.target}>
                                                        ❖ {lang(sub_menu.menu_th,sub_menu.menu_en,sub_menu.menu_cn)}
                                                        </a>
                                                        ):('❖ '+lang(sub_menu.menu_th,sub_menu.menu_en,sub_menu.menu_cn))}
                                                    </p>
                                                    <ul className={menu_classes["menu-link"]}>
                                                    {sub_menu.child.map((sub_menu_child, sub_menu_child_key) =>(
                                                        <li key={"sub_menu_child_"+sub_menu_child_key}>
                                                            <a href={process.env.PUBLIC_URL + sub_menu_child.link} target={sub_menu.target}>
                                                                {lang(sub_menu_child.menu_th,sub_menu_child.menu_en,sub_menu_child.menu_cn)}
                                                            </a>
                                                        </li>
                                                    ))}
                                                    </ul>
                                                </>
                                            ):(
                                                <p className={menu_classes["menu-header"]+" "+menu_classes["no-child"]} key={"sub_menu"+sub_menu_key}>
                                                    <a href={process.env.PUBLIC_URL + sub_menu.link} target={sub_menu.target}>
                                                    ❖ {lang(sub_menu.menu_th,sub_menu.menu_en,sub_menu.menu_cn)}
                                                    </a>
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ):""}
                    {
                    (menu.menu_id==="5")?(
                        <div className={layout["submenu"]+` invisible group-hover:visible ${menu_classes["dropdown-menu"]} ${menu_classes["one-row-1"]}`}>
                            <div className={menu_classes["dropdown-menu-wrapper"]}>
                                <div className={menu_classes["dropdown-menu-grid-1"]}>
                                    <div className={menu_classes["dropdown-menu-detail"]}>
                                        <p className={menu_classes["menu-header"]}>❖ {lang('ที่อยู่','Address','Address')} : <small>{lang(init.name_th,'CMU History Development Hall',init.name_cn)} <br/>{lang(init.address_th,init.address_en,init.address_cn)}</small></p>
                                        <p className={menu_classes["menu-header"]}>❖ {lang('ช่องทางการติดต่อ','FIND US','FIND US')}</p>
                                        <ul className={menu_classes["menu-link"]}>
                                            <li><a href={`tel:`+init.phone} className="menu-active">{lang('โทร.','Phone.','电话')} {init.phone}</a></li>
                                            <li><a href={init.facebook} target="_blank" rel="noreferrer" >Facebook : {lang(init.name_th,'CMU History Development Hall',init.name_cn)}</a></li>
                                            <li><a href={`mailto:`+init.email} target="_blank" rel="noreferrer" >EMAIL : {init.email}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):""}
                    </li>
                ))}
                </ul>
            </div>
        </div>
    )
}
