import React, { useEffect, useState } from 'react'
import ThemeContext from '../context/ThemeContext';
import { lang } from '../../components/lang'
import api from '../components/api'
import Footer from '../components/Footer'
import main from '../assets/css/main.module.css';
import logo from '../assets/main-logo.png';
import name_logo from '../assets/name-logo-brown.png';
import th_icon from '../assets/th.jpg';
import en_icon from '../assets/en.jpg';
import cn_icon from '../assets/cn.jpg';
import user1_icon from '../assets/user2.png';
import user2_icon from '../assets/user3.png';
import { FaPhoneAlt } from 'react-icons/fa';

export default function AccessRegis() {
  const [choose_lang, setChooseLang] = useState(false);
  const [language, setLang] = useState("th");
  const [user_group, setuserGroup] = useState(0);
  const [counter, setCounter] = useState(1);
  const [type_id, setTypeID] = useState("1");
  const [user_types, setUserTypes] = useState([]);
  const login_url = "https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=fPtkhqsZufuMDp2xw3UTFt06beeyBC22Mpe3Pthj&redirect_uri=https://cmumuseum.library.cmu.ac.th/api/callback&scope=cmuitaccount.basicinfo&state=usertrack";
  // const login_url = "https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=fPtkhqsZufuMDp2xw3UTFt06beeyBC22Mpe3Pthj&redirect_uri=http://localhost/cmumuseum/api/callback&scope=cmuitaccount.basicinfo&state=usertrack";

  useEffect(()=>{
    setLang(prev => localStorage.getItem("lang"));
    setChooseLang(prev => true);
    const fetchInit = async () =>{
      try {
        const response = await api.get('/access_user_types');
        setUserTypes(response.data.results);
        // console.log(response.data.results);
      } catch (err) {
          if(err.response){
              //Not in 202 response range
              console.log(err.response.data);
              console.log(err.response.headers);
              console.log(err.response.status);
          } else {
              console.log(`Error: ${err.message}`);
          }
      }
    }
    let email = localStorage.getItem("email");
    // request access token 
    if(email===null){
         window.location = login_url;
    }else{
      fetchInit();
    }
  },[]);

  const setLanguage = (lang) => {
      setLang(prev => lang);
      // console.log(lang);
      localStorage.setItem("lang", lang);
      setUserGroup(0);
      setChooseLang(false);
  }

  const setUserGroup = (user_group) => {
    if(user_group===2){
      setTypeID("4");
    }else{
      setTypeID("1"); 
    }
    setuserGroup(user_group);
  }

  const handleSubmit = (event) => {
      event.preventDefault();
      // console.log(isFinite(counter));
      if(isFinite(counter)===false||counter<=0){
          alert(lang("กรุณาระบุจำนวนผู้เข้าชม","Please insert Visitor Count","请插入访客人数"));
      }else{           
        let email = localStorage.getItem("email");
        // create user
        let jsonData = {
          type_id:type_id,
          counter:counter,
          language:language,
          create_by:email
        }
        api.post(`access_user/create`,jsonData)
        .then(response => {
            if(response.data.status==="ok"){ 
                setUserGroup(0);
                setChooseLang(true);
            }else{
                let message = JSON.stringify(response.data.message);
                alert(message);
            }
        })
        .catch(error => {
            alert(error);
        });

      }
  }

  return (
    <ThemeContext.Provider
      value={{ language }}
    >   
    <div className={main["welcome-page"]}>
      {(choose_lang===true)?
      <div className={main["home"]}>
        <div className={main["header"]}>
            <div className={main["header-logo"]}>
            <img src={logo} alt={lang("หอประวัติมหาวิทยาลัยเชียงใหม่","Chiang Mai University History Development Hall","清迈大学历史馆")} />
            <img src={name_logo} alt={lang("หอประวัติมหาวิทยาลัยเชียงใหม่","Chiang Mai University History Development Hall","清迈大学历史馆")} />
            </div>
            <div className={main["header-phone"]}><FaPhoneAlt /> 053 943967</div>
            <div className={main["header-open"]}><span>OPEN:</span><span>Mon - Fri 08:30 - 16:30<br />Sat - Sun 10.00 -18.00</span></div>
            <div className={main["triangle-right"]}></div>
        </div>
        <h1>Check-in</h1>
        <p>Select Language</p>
        <ul>
          <li onClick={()=>setLanguage("th")}>
            <img src={th_icon} alt="ภาษาไทย" />
            <p>Thai</p>
          </li>
          <li onClick={()=>setLanguage("en")}>
            <img src={en_icon} alt="ENGLISH" />
            <p>English</p>
          </li>
          <li onClick={()=>setLanguage("cn")}>
            <img src={cn_icon} alt="简体中文" />
            <p>Chinese</p>
          </li>
        </ul>
      </div>
      :
      (user_group===0)?
      <div className={main["home"]}>
        <div className={main["lang-select"]} onClick={()=>setChooseLang(true)}>
            {(language==="th")?
              <img src={th_icon} alt="ภาษาไทย" />
            :
              (language==="en")?
                <img src={en_icon} alt="ENGLISH" />
              :
                <img src={cn_icon} alt="简体中文" />
            }
        </div>
        <div className={main["title"]}>
          <h3>WELCOME</h3>
          <h3>{lang("หอประวัติมหาวิทยาลัยเชียงใหม่","Chiang Mai University History Development Hall","清迈大学历史馆")}</h3>
          <div className={main["title-hr"]} />
        </div>
        <ul className={main["user-group"]}>
          <li onClick={()=>setUserGroup(1)}>
            <img src={user1_icon} alt="ภาษาไทย" />
            <p>{lang("บุคคลภายในมหาวิทยาลัย","CMU Personal/Student","清迈大学校内人员")}</p>
          </li>
          <li onClick={()=>setUserGroup(2)}>
            <img src={user2_icon} alt="ENGLISH" />
            <p>{lang("บุคคลภายนอกมหาวิทยาลัย","Outsider","校外人员")}</p>
          </li>
        </ul>
      </div>
      :
      <div className={main["home"]}>
        <div className={main["title"]}>
          <h3>WELCOME</h3>
          <h3>{lang("หอประวัติมหาวิทยาลัยเชียงใหม่","Chiang Mai University History Development Hall","清迈大学历史馆")}</h3>
          <div className={main["title-hr"]} />
        </div>
        <ul className={main["user-type"]}>
            {(user_group===1)?
          <li>
            <img src={user1_icon} alt={lang("บุคคลภายในมหาวิทยาลัย","CMU Personal/Student","清迈大学校内人员")} />
          </li>
          :''}
          <li className={main["user-question"]}>
            <h3>{lang("ท่านเป็นผู้เยี่ยมชมกลุ่มใด","Please select your membership type","请选择您的访客类别")}</h3>
            <form>
                {user_types.map((element,index)=>
                    (parseInt(element.user_group) === user_group)?
                    <fieldset key={index}>
                      <input type="checkbox" name="type_id" checked={(type_id===element.id)?true:false} onChange={e=>setTypeID(current=>element.id)} /> <label htmlFor={element.name_en} onClick={e=>setTypeID(current=>element.id)}>{lang(element.name,element.name_en,element.name_cn)}</label>
                    </fieldset>
                    :''
                )}
                <fieldset>
                  <label>{lang("ระบุจำนวนผู้เข้าชม","Visitor Count Tracking","访客人数")}</label> <input type="number" name="counter" id="counter" value={counter} onChange={e=>setCounter(e.target.value)} />
                  <label>{lang("คน","person(s)","人")}</label> 
                </fieldset>
                <fieldset className={main["btn-group"]}>
                    <button type="button" onClick={e=>handleSubmit(e)}>{lang("ตกลง","Submit","提交")}</button>
                    <button type="button" onClick={e=>setUserGroup(0)} className={main["cc-btn"]}>{lang("กลับหน้าหลัก","Back","返回主页")}</button>
                </fieldset>
            </form>
          </li>
            {(user_group===2)?
          <li>
            <img src={user2_icon} alt={lang("บุคคลภายนอกมหาวิทยาลัย","Back","校外人员")} />
          </li>
          :''}
        </ul>
      </div>
      }
      <Footer styles={main} />
    </div>
    </ThemeContext.Provider>
  )
}