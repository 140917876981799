import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import { lang, img_url } from '../components/lang'
import { useLocation } from "react-router-dom";
import api from '../components/api'
import styles from '../assets/css/ebook.module.css';
import SouvenirContext from '../context/SouvenirContext';

const Menu = lazy(() => import ('../components/Template/Menu'))
const Nav = lazy(() => import ('../components/Template/Nav'))
const Lists = lazy(() => import ('../components/Resource/Lists'))
const Detail = lazy(() => import ('../components/Resource/Detail'))

export default function Ebook(props) {
    // const [init, setInit] = useState([]);
    // const [book_type, setBookType] = useState('all');
    const [nav_data, setNavData] = useState([]);
    const [filterResources, setFilterResources] = useState([]);
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    let keyword = (params.get('keyword'))?params.get('keyword'):'';
    let itemid = (params.get('id'))?params.get('id'):0;
    const [filter, setFilter] = useState(keyword);
    const type_params = useParams();
    const [type, setType] = useState('souvenir');
    const [title, setTitle] = useState('ของที่ระลึก');
    const [item_id, setItemID] = useState(itemid);
    const [resource, setResource] = useState({});
    const [resources, setResources] = useState([]);
    const [souvenir_type, setSouvenirType] = useState(0);
    const [country, setCountry] = useState(0);
    const [institute, setInstitute] = useState(0);
    const [student_year, setStudentYear] = useState(0);
    const location = useLocation();
    let path = location.pathname.split("/");

    useEffect(()=>{
        let type = 'souvenir';
        if(props.type===''){
          if(type_params.type){
              type = type_params.type;
              setType(type);
          }
        }else{
          type = props.type;
          setType(props.type);
        }
        let itemId = (type_params.itemId)?type_params.itemId:itemid;
        setItemID(itemId);
        // console.log(itemId);
        const fetchInit = async () =>{
            if(itemId!==0){
              try {
                if(type==='souvenir'){
                    const response = await api.get('/souvenir/'+itemId);
                    setResource(current => response.data.results);
                    setTitle('ของที่ระลึก');
                }
                if(type==='alumni'){
                    const response = await api.get('/alumni/'+itemId);
                    setResource(current => response.data.results);
                    setTitle('นักศึกษาเก่าดีเด่น');
                }
              } catch (err) {
                  if(err.response){
                      //Not in 202 response range
                      console.log(err.response.data);
                      console.log(err.response.headers);
                      console.log(err.response.status);
                  } else {
                      console.log(`Error: ${err.message}`);
                  }
              }
            }
          try {
            const response = await api.get('/menu?type=left-menu');
            setNavData(response.data.results);
            response.data.results.forEach(element => {
                if(('/'+path[1])===element.link){
                    setTitle(lang(element.menu_th,element.menu_en,element.menu_cn));
                }
            });
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        //   try {
        //     const response = await api.get('/setting');
        //     setInit(response.data.results);
        //   } catch (err) {
        //       if(err.response){
        //           //Not in 202 response range
        //           console.log(err.response.data);
        //           console.log(err.response.headers);
        //           console.log(err.response.status);
        //       } else {
        //           console.log(`Error: ${err.message}`);
        //       }
        //   }
          try {
            if(type==="souvenir"){
                const response = await api.get('/souvenir?stype='+souvenir_type+'&country='+country+'&institute='+institute);
                setResources(current => response.data.results);
                if(keyword!==''){
                    let filteredItems = response.data.results.filter(
                        item => item.register.toLowerCase().includes(filter.toLowerCase()) || 
                        item.detail.toLowerCase().includes(filter.toLowerCase()),
                    );
                    setFilterResources(filteredItems);
                }else{
                    setFilterResources(response.data.results);
                }
            }
            if(type==="alumni"){
                const response = await api.get('/alumni?year='+student_year);
                setResources(current => response.data.results);
                setFilterResources(response.data.results);
            }
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        fetchInit();
        window.addEventListener("scroll", reveal);
        reveal();

    },[]);

    useEffect(()=>{
        const fetchData = async () =>{
            try {
                const response = await api.get('/souvenir?stype='+souvenir_type+'&country='+country+'&institute='+institute);
                setResources(current => response.data.results);
                setFilterResources(current => response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchData();
    },[souvenir_type,country,institute]);
    
    useEffect(()=>{
        const fetchData = async () =>{
            try {
                const response = await api.get('/alumni?year='+student_year);
                setResources(current => response.data.results);
                setFilterResources(current => response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchData();
    },[student_year]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    return (
        <SouvenirContext.Provider
          value={{ souvenir_type, country, institute }}
        >   
        <div className={styles["resource-container"]}>
          <div className={styles["ebook-container"]}>
              <Nav styles={styles} nav_data={nav_data} />
              <div className={styles["content"]+" reveal"}>
                  <Menu student_year={student_year} item_id={item_id} setStudentYear={setStudentYear} type={type} setType={setType} setSouvenirType={setSouvenirType} setCountry={setCountry} setInstitute={setInstitute} setFilter={setFilter} filter={filter} right={true}/>
                  <div className={styles["content-container"]}>
                  {(item_id===0)?(
                    <Suspense fallback={<h1>Loading Resource</h1>}>
                        <Lists styles={styles} data={filterResources} title={title} type={type} setItemID={setItemID} setResource={setResource}/>
                    </Suspense>
                  ):(
                    <Suspense fallback={<h1>Loading Item</h1>}>
                        <Detail styles={styles} data={resource} title={title} item_id={item_id} type={type} setResource={setResource}/>
                    </Suspense>
                  )}
                  </div>
              </div>
            </div>
        </div>
    </SouvenirContext.Provider>
    )
}