import React, { useContext, useState, useEffect } from 'react'
import { gotoURL, addLeadingZeros, validateEmail } from '../lang'
import { loadCaptchaEnginge, LoadCanvasTemplate , validateCaptcha} from "react-simple-captcha";
import ThemeContext from '../../context/ThemeContext';
import api from '../api'
import axios from 'axios';
import Nav from './Nav'
import classes from '../../assets/css/module/form.module.css';

export default function Form({ styles }) {
    const { language } = useContext(ThemeContext);
	const today = new Date().toISOString().split('T')[0];
    const [error, setError] = useState(0);
    const [success, setSuccess] = useState(0);
    const [msg, setMsg] = useState('');
    const [bdate, setBookingDate] = useState(today);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [start_time, setStartTime] = useState('');
    const [end_time, setEndTime] = useState('');
    const [remark, setRemark] = useState('');
	const [agency, setAgency] = useState('');
	const [ip, setIP] = useState('');
	const [total, setTotal] = useState(1);
    const [config, setConfig] = useState({});
    const [all_events, setAllEvents] = useState([]);
    const [start_times, setStartTimes] = useState([]);
    const [end_times, setEndTimes] = useState([]);

    function lang(name_th, name_en, name_ch){
        let langu = language;
        if(langu===null){
            langu = "th";
            localStorage.setItem("lang","th");
        } 
        if((name_en===''||name_en===null)&&langu==='en'){
            name_en = name_th;
            localStorage.setItem("warning",true);
        }
        if((name_ch===''||name_ch===null)&&langu==='cn'){
            name_ch = name_en;
            if(name_en===''||name_en===null){
                name_ch = name_th;
            }
            localStorage.setItem("warning",true);
        }
    
        switch(langu) {
            case 'th':
                return name_th;
            case 'cn':
                return name_ch; 
            default:
                return name_en;
        }
    }

    useEffect(()=>{
        setError(0);
        setSuccess(0);
        fetchInit();
        loadCaptchaEnginge(4);
    },[]);

    const fetchInit = async () =>{
        try {
            const res = await axios.get('https://geolocation-db.com/json/');
            setIP(res.data.IPv4);
            const response = await api.get('/rsvn_setting');
            const result = response.data.results;
            setConfig(result);
            let time_lists = getTimeList(result.start,result.end,30);
            setStartTimes(time_lists);
            setEndTimes(time_lists);
            setStartTime(time_lists[0]);
            setEndTime(time_lists[1]);

            const holiday_response = await api.get('/holiday');
            const holiday_results = holiday_response.data.results;
            let allevents = [];
            holiday_results.forEach(element => {
                var end_date = new Date(element.end);
                let item = {
                      title: lang(element.detail,element.detail_en),
                  //   start  : new Date(element.start),
                  //   end  : end_date.setDate(end_date.getDate() + 1),
                      start  : element.start+'T00:00:01',
                      end  : element.end+'T23:59:59',
                      item  : element,
                      type  : "holiday",
                      allDay : true
  
                }
                allevents.push(item);
            });
            setAllEvents(allevents);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }
    
    const getTimeList = (start_time, end_times, mins) => {
        
        let start = new Date("2023-02-27 "+start_time)
        let end = new Date("2023-02-27 "+end_times)

        let slots = [];
        while (start <= end) {
            // console.log("start:"+start);
            // only mins
            //slots.push(date(start).getMinutes());
            
            let d = new Date(start);
            // hrs +  mins
            slots.push(`${addLeadingZeros(d.getHours(),2)}:${addLeadingZeros(d.getMinutes(),2)}`);
            start.setMinutes(start.getMinutes() + mins);
        }
        // console.log(slots);
        return slots;
    }

    const onChangeDate = (date_value) => {
        setBookingDate(date_value);
    }

    function handleSubmit(event){
        event.preventDefault();
        setError(0);
        setSuccess(0);
        setMsg('');
        let user_captcha = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha)===true) {
            // document.getElementById('user_captcha_input').value = "";
            if(!bdate){
                let message = lang('กรุณากรอกวันที่จอง..','Please enter Booking Date..','请输入预订日期..');
                setError(1);
                setMsg(message);
                return
            }else if(!name){
                let message = lang('กรุณากรอกชื่อผู้จอง..','Please enter Booking By..',"请输入预名字..");
                setError(1);
                setMsg(message);
                return
            }else if(!email||!validateEmail(email)){
                let message = lang('กรุณาตรวจสอบอีเมล์ผู้จอง..','Please enter valid Email..',"请输入有效的邮箱..");
                setError(1);
                setMsg(message);
                return
            }else if(isHoliday(bdate)){
                let message = lang('ไม่สามารถจองในวันนี้ได้เนื่องจากเป็นวันหยุด..','Can\'t make a reservation at today..','今天不能预订..');
                setError(1);
                setMsg(message);
                return
            }else if(isWeekEnd(bdate)){
                let message = lang('ไม่สามารถจองในวันนี้ได้เนื่องจากเป็นวันหยุด..','Can\'t make a reservation at today..','今天不能预订..');
                setError(1);
                setMsg(message);
                return
            }else{
                // create new booking
                // console.log("create new booking");
                const jsonData = {
                    bdate: bdate,
                    start_time: start_time,
                    end_time: end_time,
                    name: name,
                    email: email,
                    phone: phone,
                    remark: '',
                    total: total,
                    agency: agency,
                    status: 1,
                    user: name,
                    ip: ip,
                } 
                api.post(`rsvn/create`,jsonData)
                .then(response => {
                    if(response.data.status===true){
                        setSuccess(1);
                        loadCaptchaEnginge(4);

                        setBookingDate(today);
                        setStartTime('');
                        setEndTime('');
                        setName('');
                        setEmail('');
                        setPhone('');
                        setTotal('');
                        setAgency('');
                        setRemark('');
                        document.getElementById('user_captcha_input').value = "";
                    }else{
                        setError(1);
                        let message = JSON.stringify(response.data.message);
                        setMsg(message);
                    }
                })
                .catch(error => {
                    setError(1);
                    setMsg(error);
                });
            }
        }else{
            let message = 'The Captcha is invalid..';
            setError(1);
            setMsg(message);
        }
    }

    const isHoliday = (event_date) => {
        const filterEvent = all_events.filter((element: any) => {
            // console.log(new Date(element.start).toISOString().split('T')[0]);
            if((new Date(event_date).getTime() <= new Date(element.end).getTime() && new Date(event_date).getTime() >= new Date(element.start).getTime())) {
            //   console.log(event_date);
            //   console.log(element.end);
            //   console.log(element.start);
                return element.type === 'holiday' 
            }
        });
  
        if(filterEvent.length>0){
            return true;
        }else{
            return false;
        }
  
    }

    const isWeekEnd = (event_date) => {
        var dayOfWeek = new Date(event_date).getDay();
        if((dayOfWeek === 6) || (dayOfWeek  === 0)){
            return true;
        }else{
            return false;
        }
  
    }

    return (
        <article className={styles["article"]}>
            <Nav styles={styles} />
            <div className={styles["article-content"]}>
                <h3>{lang('จองเข้าเยี่ยมชม','Reservation','预订')}</h3>
            <div className={classes["booking-form"]}>
                {(error===1)?(
                    <div className={classes["notice"]+" "+classes["danger"]}>
                        <div><i className="fa-solid fa-triangle-exclamation"></i></div>
                        <div className={classes["info"]}>
                            <b>{lang('ไม่สามารถบันทึกข้อมูลได้','Can\'t booking..','Can\'t booking..')}</b><br />
                            {msg}
                        </div>
                        <i className="fa-solid fa-xmark btn-close" onClick={(event)=>setError(0)}></i>
                    </div>
                    ):''}
                {(success===1)?(
                <div className={classes["notice"]+" "+classes["active"]}>
                    <div><i className="fa-regular fa-file-lines"></i></div>
                    <div className={classes["info"]}>
                        <b>{lang('บันทึกข้อมูลเรียบร้อย!','Successfuly!','Thank You!')}</b>
                        <br />{lang('ทำรายการบันทึกข้อมูลเรียบร้อย..','Complete booking!','Complete booking!')}
                    </div>
                    <div>
                        <i className="fa-solid fa-xmark btn-close" onClick={(event)=>{setSuccess(0);loadCaptchaEnginge(4);}}></i>
                    </div>
                </div>
                ):''}
                <form className={classes["main-form"]}>
                    <fieldset>
                        <label htmlFor="booking_by">{lang('ชื่อผู้จอง','Booking By','名字')} <span className={classes["red"]}>*</span></label>
                        <input type="text" name="booking_by" id="booking_by" value={(name)?name:''} onChange={e=>setName(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="faculty">{lang('หน่วยงานที่สังกัด',"Agency",'办事处')} <span className={classes["red"]}>*</span></label>
                        <input type="text" name="faculty" value={agency} onChange={e=>setAgency(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="total">{lang('จำนวนคน',"User Group","人数")} <span className={classes["red"]}>*</span></label>
                        <input type="number" name="total" value={total} onChange={e=>setTotal(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="booking_email">{lang('อีเมล์ผู้จอง','Email','电邮')} <span className={classes["red"]}>*</span></label>
                        <input type="text" name="booking_email" id="booking_email" value={(email)?email:''} onChange={e=>setEmail(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="phone">{lang('โทรศัพท์','Phone','电话')} <span className={classes["red"]}>*</span></label>
                        <input type="text" name="phone" id="phone" value={(phone)?phone:''} onChange={e=>setPhone(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="booking_date">{lang('วันที่จอง','Booking Date','预订日期')}</label>
                        <input type="date" min={today} name="booking_date" id="booking_date" value={(bdate)?bdate:''} onChange={e=>onChangeDate(e.target.value)} />
                    </fieldset>
                    <fieldset className={classes["booking-select"]}>
                        <label htmlFor="booking_time">{lang('เวลา','Booking Time','时间')}</label>
                        <select name="start_time" id="start_time" value={(start_time)?start_time:0} onChange={e=>setStartTime(e.target.value)}>
                            {start_times.map((option,index) => (
                                (index!==start_times.length-1)?
                                    <option value={option} key={'start-'+index}>{option}</option>
                                :''
                            ))}
                        </select><span> {lang('ถึง','to','到')} </span>
                        <select name="end_time" id="end_time" value={(end_time)?end_time:start_time} onChange={e=>setEndTime(e.target.value)}>
                            {end_times.map((option,index) => (
                                <option value={option} key={'end-'+index}>{option}</option>
                            ))}
                        </select>
                    </fieldset>
                    {/* <fieldset>
                        <label htmlFor="title">{lang('รายละเอียด','Detail','细节')}</label>
                        <textarea name="title" id="title" value={(remark)?remark:''} onChange={e=>setRemark(e.target.value)} />
                    </fieldset> */}
                    <fieldset className={classes["captcha"]}>
                        <label htmlFor="captcha"><LoadCanvasTemplate /></label>
                        <input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                    </fieldset>
                    <fieldset>
                        <button onClick={e=>handleSubmit(e)} className={classes["btn"]}>{lang('จองเวลาเข้าเยี่ยมชม','Booking','预约')}</button>
                        <div onClick={e=>gotoURL('/')} className={classes["btn-light"]}>{lang('กลับหน้าหลัก','Back to Home','返回首页')}</div>
                    </fieldset>
                </form>
            </div>
        </div> 
        </article>
    )
}