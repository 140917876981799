import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import api from '../components/api';
import axios from 'axios';
import logo from '../../assets/logo.png'
import '../assets/css/authen.css';

export default function Callback() {
    const img = [
      'pic_01.jpg',
      'pic_02.jpg',
      'pic_03.jpg',
      'pic_04.jpg',
      'pic_05.jpg',
    ]

    let [error, setError] = useState('');
    let [info, setInfo] = useState('');
    let [showError, setShowError] = useState(false);
    let [showInfo, setShowInfo] = useState(false);
    let [random_id, setRandomID] = useState(0);
    let [image, setImage] = useState('');
    let [timer, setTimer] = useState(5);
    let [searchParams, setSearchParams] = useSearchParams();
    const login_url = "https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=fPtkhqsZufuMDp2xw3UTFt06beeyBC22Mpe3Pthj&redirect_uri=https://cmumuseum.library.cmu.ac.th/api/callback&scope=cmuitaccount.basicinfo&state=usertrack";
    // const login_url = "https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=fPtkhqsZufuMDp2xw3UTFt06beeyBC22Mpe3Pthj&redirect_uri=http://localhost/cmumuseum/api/callback&scope=cmuitaccount.basicinfo&state=usertrack";
    let redirectpage = login_url;
    let checkTwice=0;
    
    useEffect(() => {
        if(image===''){
            setRandomID(Math.floor(Math.random() * (2)));
            setImage(img[random_id]);
        }
        
        const fetchInit = async (code) =>{
            try {
                const ip_response = await axios.get('https://geolocation-db.com/json/');
                // setIP(res.data.IPv4);
                const response = await api.get(`checkcallback?access_token=${code}`)
                if(response.data.status==="ok" && response.data.Message !=="Unauthorized"){
                    const cmu_account = response.data.cmu_account;
                    // check user is admin or not
                    const admin_response = await api.get(`admin?email=${response.data.cmuitaccount}`)
                    if(admin_response.data.status==="ok"){
                        localStorage.setItem('email',response.data.cmuitaccount);
                        localStorage.setItem('ip',ip_response.data.IPv4);
                        localStorage.setItem('cmu_account',JSON.stringify(cmu_account));
                        localStorage.setItem('token',response.data.access_token);
                        
                        // update user session
                        let data = {
                            email: response.data.cmuitaccount,
                            ip: ip_response.data.IPv4,
                            token: code,
                            user: cmu_account,
                            cmu_account: cmu_account,
                        }
                        const admin_session = await api.post(`admin/session`,data);
                        if(admin_session.data.status!=="ok"){
                            setShowError(true);
                            setError(admin_session.data.message);
                        }
                    }else{
                        setShowError(true);
                        setError(admin_response.data.message);
                    }
                }

            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        const get_code =searchParams.get("code");
        // request access token 
        if(get_code===null){
             window.location = redirectpage;
        }else{
            fetchInit(get_code);
        }
    },[]);

    useEffect(() => {
        if(!showError){
            let cerror = searchParams.get("error");
            let error_description = searchParams.get("error_description");
            if(cerror===null&&error_description==null){
                if (timer > 0) {
                    setTimeout(() => setTimer(timer - 1), 1000);
                } else {
                    clearTimeout();
                    setTimer('0');
                    window.location = process.env.PUBLIC_URL +'/welcome';
                }
            }else{
                setShowError(true);
                setError(error_description);
            }
        }
    });

    return (
        <div className="login">
            <section>
                <div className="left">
                    <div className="form">
                        <h2>ยินดีต้อนรับ</h2>
                        <p>เข้าสู่การเก็บข้อมูลการเข้า-ออก อาคาร</p>
                        {(showError)?(
                        <>
                            <div className="alert alert-danger">
                                <p>
                                    <b>Error:</b> {error}
                                </p>
                            </div>
                            <fieldset>
                                <p className="register">
                                    <i className="fa-solid fa-key"></i> 
                                    Please&nbsp;
                                    <a href={process.env.PUBLIC_URL + "/authen"} className="a-links">re-login</a> 
                                    &nbsp;for authen you account&nbsp;
                                </p>
                            </fieldset>
                        </>
                        ):(
                        <>
                            {(showInfo)?(
                                <div className="alert alert-info">
                                    <p className="register">
                                        <b>{info}:</b><br /> You should be automatically redirected in <span className="text-red-400">{timer}</span> seconds.
                                    </p>
                                </div>
                            ):(
                                <div className="alert alert-info">
                                    <p className="register">
                                        <b>Successful login:</b><br /> You should be automatically redirected in <span className="text-red-400">{timer}</span> seconds.
                                    </p>
                                </div>
                            )}
                            
                            {(showInfo)?(
                            <p className="mb-3 float-left">
                                    <a href={process.env.PUBLIC_URL + "/welcome"} className="a-links red">Click here</a>&nbsp;to redirect to last page 
                            </p>
                            ):''}
                            {(showInfo)?(
                            <p className="float-right"> 
                                <i className="fa-solid fa-key"></i>
                                    If not you please&nbsp;
                                    <a href={login_url} className="a-links">re-login</a> 
                            </p>
                            ):''}
                            
                        </>
                        )}
                        
                    </div>
                </div>
                <div className="right" style={{ backgroundImage: `url(${(image)?process.env.PUBLIC_URL + '/images/'+image:''})`}}>
                    <div className="info">
                        <a href="https://hmk84.cmu.ac.th/" target="_blank" rel='noreferrer' className="logo">
                            <img src={logo} alt="หน้าแรก" />
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}
