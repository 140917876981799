import React, { useEffect, useState } from 'react'
import api from './api'
import { FaPhone, FaFacebookF} from 'react-icons/fa';

const Footer = ({styles}) => {
  const [authen, setAuthen] = useState(false);
  const login_url = '';
  useEffect(()=>{
  },[]);

  return (
    <footer className={styles["footer"]}>
        <ul>
            <li>
                <div className={styles["rounded-btn"]}><FaFacebookF /></div>
                <a href="https://www.facebook.com/cmuhistoryhall/" rel="noreferrer" target="_blank">CMUHistoryhall</a>
            </li>
            <li>
                <div className={styles["rounded-btn-white"]}><FaPhone /></div>
                <a href="tel:053 943 967" rel="noreferrer" target="_blank">054 943 967</a>
            </li>
        </ul>
    </footer>
  )
}

export default Footer