import React, { useEffect, lazy } from "react"
import {Navigate, Routes, Route } from "react-router-dom"
import Layout from "./components/Layout"
import ReactGA from 'react-ga';
import Home from "./views/Home";
import Article from "./views/Article";
import Reservation from "./views/Reservation";
import Resource from "./views/Resource";
import Person from "./views/Person";
import AccessRegis from "./usertrack/views/Home";
import AccessRegisCallback from "./usertrack/views/Callback";
const TRACKING_ID = "G-RB0QHFC6LF"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

function App() {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
        <Route path="/" element={<Layout />} >
            <Route path="*" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/rsvn" element={<Reservation />} />
            <Route path="/activities" element={<Article articleType={"2"} />} />
            <Route path="/article/:articleId" element={<Article />} />
            <Route path='/about' element={<Navigate to='/article/1' />} />
            <Route path='/souvenir'  element={<Resource type="souvenir" />} />
            <Route path="/souvenir/:itemId" element={<Resource type="souvenir" />} />
            <Route path='/alumni'  element={<Resource type="alumni" />} />
            <Route path="/alumni/:itemId" element={<Resource type="alumni" />} />
            <Route path='/doctor'  element={<Person type={1} person_type="doctor" />} />
            <Route path="/doctor/:itemId" element={<Person type={1} person_type="doctor" />} />
            <Route path='/professor'  element={<Person type={2} person_type="professor" />} />
            <Route path="/professor/:itemId" element={<Person type={2} person_type="professor" />} />
            <Route path='/president/:typeId'  element={<Person type={3} person_type="president" />} />
            <Route path="/hof/:itemId" element={<Person type={3} person_type="president" />} />
        </Route>
        <Route path="/welcome" element={<AccessRegis />} />
        <Route path="/callback" element={<AccessRegisCallback />} />
    </Routes>
  );
}

export default App;
