import React, { useState, useEffect, useRef } from 'react';
import { Outlet,useLocation } from "react-router-dom";
import { lang, page_url, getTextSize, cookies, openLink, gotoURL, gotoLink, check_brightness, chk_img } from './lang';
import { BsLine } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { RiMenuFill, RiCloseFill, RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaYoutube, FaTiktok, FaAlignRight, FaChevronDown } from 'react-icons/fa';
import api from './api';
import layout from '../assets/css/module/layout.module.css';
import ThemeContext from '../context/ThemeContext';
import Menu from './Template/NavMenu';
import darklogo from '../assets/dark-logo.png';
import logo from '../assets/logo.png';
import logo_name from '../assets/name-logo.png';
import logo_name_white from '../assets/name-logo-white.png';
import mainlogo from '../assets/main-logo.png';

export default function Layout() {
    const [footer_menus, setFooterMenus] = useState([]);
    const [search_type, setSearchType] = useState('CMUDC');
    const [keyword, setKeyword] = useState('');
    const [open_menu, setOpenMenu] = useState(false);
    const [show_lang, setShowLang] = useState(false);
    const [show_lang2, setShowLang2] = useState(false);
    const [language, setLang] = useState("th");
    const [selected_menu, setSelectMenu] = useState([1]);
    const [menu_level, setMenuLevel] = useState(0);
    const [page, setPage] = useState("About Us");
    const [font_size, setFontSize] = useState("small");
    const [init, setInit] = useState({src:undefined});
    const [header_color, setHeaderColor] = useState("#fff");
    const [bg_color, setBGColor] = useState("#fff");
    const [logo_header, setLogoHeader] = useState("");
    const [logo_footer, setLogoFooter] = useState("");
    const header_ref = useRef(null);
    const body_ref = useRef(null);
    const lang_ref = useRef(null);
    const lang_ref2 = useRef(null);
    const location = useLocation();

    const [visitorCount, setVisitorCount] = useState([]);

    const fetchMenus = async () =>{
        try {
            const init_response = await api.get('/setting');
            setInit(init_response.data.results);
            const home_config = JSON.parse(init_response.data.results.config);
            setHeaderColor(home_config.header_color);
            setBGColor(home_config.bg_color);
            setLogoHeader(init_response.data.results.logo_header);
            setLogoFooter(init_response.data.results.logo_footer);
            const foot_menu = await api.get('/menu?type=footer-menu');
            setFooterMenus(foot_menu.data.results);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    const fetchVisitor = async () => {
      try {
        let visitor = [];
      
        // const response = await api.get('/visitors');
        // let result = response.data;
        // visitor.push({
        //   total:result.today,
        //   title_th:"วันนี้",
        //   title_en:"Today"
        // }); 
        visitor.push({
        //   total:result.yesterday,
          total:1,
          title_th:"วันนี้",
          title_en:"Today"
        }); 
        visitor.push({
        //   total:result.month,
          total:10,
          title_th:"เดือนนี้",
          title_en:"This Month"
        }); 
        // visitor.push({
        // //   total:result.year,
        //   total:10,
        //   title_th:"ปีนี้",
        //   title_en:"This Year"
        // });
        visitor.push({
          //   total:result.all,
          total:10,
          title_th:"สถิติทั้งหมด",
          title_en:"All"
        }); 
        setVisitorCount(visitor);
        localStorage.setItem("visitor",JSON.stringify(visitor));
        localStorage.setItem("last_visited",new Date().toLocaleDateString());
        
      } catch (err) {
          if(err.response){
              //Not in 202 response range
              console.log(err.response.data);
              console.log(err.response.headers);
              console.log(err.response.status);
          } else {
              console.log(`Error: ${err.message}`);
          }
      }
    }

    useEffect(()=>{
          fetchMenus();
          let text_size = cookies.get('text-size');
          setFontSize(text_size);
          setLang(prev => localStorage.getItem("lang"));

          let visitor=localStorage.getItem("visitor");
          // console.log(visitor);
          if(!visitor){
              fetchVisitor();
          }else{
            var todayDate = new Date().toLocaleDateString();
            let lastVisit=localStorage.getItem("last_visited");
            if(lastVisit!==todayDate){
              fetchVisitor();
            }else{
              
              setVisitorCount(JSON.parse(visitor));
            }
          }
    },[]);

    const handleMouseMove = (event) => {
        var xp = 0, yp = 0;
        var elems = document.getElementById("mouse_icon");
        let mouse_pos = 7;
        if(elems.classList.contains(layout.big)){
            mouse_pos=30;
        }
        xp = event.pageX - mouse_pos;
        yp = event.pageY - mouse_pos; 
            
        // xp += ((mouseX - xp)/6);
        // yp += ((mouseY - yp)/6);
        if(xp>0&&yp>0){
            elems.style.left= xp+"px";
            elems.style.top= yp+"px";
            // console.log(elems);
        }else{
            if(xp<=0){
                elems.style.disp= "0px";
            }
            if(yp<=0){
                elems.style.top= "0px";
            }
        }
        
        const $hoverables = document.querySelectorAll('button');
        for (let i = 0; i < $hoverables.length; i++) {
          $hoverables[i].addEventListener('mouseenter', onMouseHover);
          $hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
        }

        const $selectables = document.querySelectorAll('.hoverables');
        for (let i = 0; i < $selectables.length; i++) {
          $selectables[i].addEventListener('mouseenter', onMouseHover);
          $selectables[i].addEventListener('mouseleave', onMouseHoverOut);
        }
        
        // setInterval(function(){
        // }, 20);
        // setInterval(function(){
        //     xp += ((mouseX - xp)/6);
        //     yp += ((mouseY - yp)/6);
        //     setMousePos({x: xp, y: yp});
        //     console.log({x: xp, y: yp});
        // }, 30);
    }

    // Hover an element
    function onMouseHover() {
      var elems = document.getElementById("mouse_icon");
      elems.classList.add(layout.big);
    }

    function onMouseHoverOut() {
        var elems = document.getElementById("mouse_icon");
        elems.classList.remove(layout.big);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        // console.log(search_type);
        if(search_type==='CMUDC'){
            document.getElementById('invisible_form').submit();
        }else if(search_type==='search website'){
            window.location = page_url+"search?keyword="+keyword;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    const closeMenu = (event) => {
        event.preventDefault();
        setOpenMenu(false);
    }

    const openMenu = (event) => {
        event.preventDefault();
        setOpenMenu(true);
    }

    const setLanguage = (lang) => {
        setLang(prev => lang);
        localStorage.setItem("lang", lang);
        setShowLang(false);
        setShowLang2(false);
    }

    const toggleLanguage = (e) => {
        e.preventDefault();
        setShowLang(current=>!current);
    }

    const setFont = (text_size) => {
        setFontSize(prev => text_size);
        cookies.set('text-size', text_size, { path: '/' });
        body_ref.current.ClassName = getTextSize();
    }

    const handleClickOutside = (event) => {
        if (lang_ref.current && !lang_ref.current.contains(event.target)) {
            setShowLang(false);
        }
    }

    const handleSelectMenu = (menu, level) =>{
        let menu_id = parseInt(menu.menu_id);
        let current = selected_menu;
        if(menu.child.length>0){
            setMenuLevel(1);
        }
        if(level===0){
            setSelectMenu(ele=>[menu_id]);
            if(menu_id===1){
                gotoURL(null);
            }
        }else{
            current[menu_level] = menu_id;
            setSelectMenu(ele=>[...current]);
        }
    }

    useEffect(()=>{
        if(show_lang===true){
          document.addEventListener("mousedown", handleClickOutside);
        }else{
          document.removeEventListener("mousedown", handleClickOutside);
        }
    },[show_lang]);

    const toggleLanguage2 = (e) => {
        e.preventDefault();
        setShowLang2(current=>!current);
    }

    const handleClickOutside2 = (event) => {
        if (lang_ref2.current && !lang_ref2.current.contains(event.target)) {
            setShowLang2(false);
        }
    }

    useEffect(()=>{
          if(show_lang===true){
            document.addEventListener("mousedown", handleClickOutside2);
          }else{
            document.removeEventListener("mousedown", handleClickOutside2);
          }
    },[show_lang2]);

    useEffect(()=>{
        var elems = document.getElementById("mouse_icon");
        // if(open_menu===true){
        //     window.addEventListener('mousemove', handleMouseMove);
        //     elems.classList.remove("hidden");
        // }else{
        //     elems.classList.add("hidden");
        //     window.removeEventListener('mousemove', handleMouseMove);
        // }
    },[open_menu]);

    
    return (
    <ThemeContext.Provider
      value={{ language, page, init }}
    >    
    {/* style={{background:`linear-gradient(to right, ${header_color} 0%, ${header_color} 100%)`}} */}
        <header ref={body_ref} className={layout["drop-shadow"]+' '+getTextSize()} style={{background:`linear-gradient(to right, ${header_color} 0%, ${header_color} 100%)`}}>
            <nav className={layout["nav"]}>
                <div className={layout["nav-container"]}>
                    <div className={layout["logo"]}>
                        <div className={layout["logo-img"]}onClick={e=>gotoURL('/')}>
                            <img src={chk_img(logo_header)} alt={lang(init.name_th,init.name_en,init.name_cn)} />
                            {/* <img src={mainlogo} alt={lang(init.name_th,init.name_en,init.name_cn)} />
                            <img src={(check_brightness(header_color))?logo_name:logo_name_white} alt={lang(init.name_th,init.name_en,init.name_cn)} /> */}
                        </div>
                        <div className={layout["top-nav"]}>
                            <div className={layout["tool"]}>
                            <ul className={layout["language"]}>
                                <li className={language==="th"?layout['active']:''}><button onClick={()=>setLanguage("th")}>TH</button></li>
                                <li className={language==="en"?layout['active']:''}><button onClick={()=>setLanguage("en")}>EN</button></li>
                                <li className={language==="cn"?layout['active']:''}><button onClick={()=>setLanguage("cn")}>CN</button></li>
                            </ul>
                            <ul className={layout["font-size"]}>
                                <li className={font_size==="small"?layout['active']:''}><button onClick={()=>setFont("small")}>ก</button></li>
                                <li className={font_size==="normal"?layout['active']:''}><button onClick={()=>setFont("normal")}>ก</button></li>
                                <li className={font_size==="large"?layout['active']:''}><button onClick={()=>setFont("large")}>ก</button></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <Menu language={language} setLanguage={setLanguage} init={init} layout={layout} header_color={header_color} font_size={font_size} setFont={setFont} />
                </div>
            </nav>
        </header>
        <main className={getTextSize()}>
            <Outlet />
        </main>
        {(init)?
        <footer className={layout.footer+' '+getTextSize()} style={{'--bg-color':bg_color,'--txt-color':(check_brightness(bg_color))?'#374151':'#fff'}}>
            <div className={layout["footer-header"]}>
                {/* <img src={(check_brightness(bg_color))?logo:darklogo} alt={lang("สำนักหอสมุด มหาวิทยาลัยเชียงใหม่","Chiang Mai University Library","清迈大学图书馆")} onClick={e=>gotoLink("https://library.cmu.ac.th/")} /> */}
                <img src={chk_img(logo_footer)} alt={lang("สำนักหอสมุด มหาวิทยาลัยเชียงใหม่","Chiang Mai University Library","清迈大学图书馆")} onClick={e=>gotoLink("https://library.cmu.ac.th/")} />
                <p>{lang('© สงวนลิขสิทธิ์ เว็บไซต์หอประวัติมหาวิทยาลัยเชียงใหม่ โดย สำนักหอสมุด มหาวิทยาลัยเชียงใหม่','Copyright©2023 Chiang Mai University Library. All rights reserved','')}</p>
            </div>
            <div className={layout["footer-detail"]}>
                <div className={layout["contact-us"]}>
                    <h5>{lang('ช่องทางการติดต่อ','FIND US','FIND US')}</h5>
                    <p>{lang(init.address_th,init.address_en,init.address_cn)}<br />
                    {lang('โทรศัพท์','Phone','Phone')}: {init.phone}<br />
                    {lang('อีเมล์','Email','Email')}: {init.email}</p>
                    <h5>{lang('เวลาให้บริการ','OPENING HOURS','OPENING HOURS')}</h5>
                    <p>{lang(init.open_info_th,init.open_info_en,init.open_info_cn)}</p>
                </div>
                <div className={layout["contact-us"]}>
                    <h5>เว็บไซต์ที่เกี่ยวข้อง</h5>
                    <ul className={layout["link"]}>
                        {footer_menus.map((item,index)=>
                            <li key={'footer-menu-'+index}>
                                <a href={item.link} rel="noreferrer" target={item.target}>{lang(item.menu_th,item.menu_en,item.menu_cn)}</a>
                            </li>
                        )}
                        {/* <li><a href="http://lannainfo.library.cmu.ac.th/" rel="noreferrer" target="_blank">ศูนย์สนเทศภาคเหนือ มหาวิทยาลัยเชียงใหม่</a></li>
                        <li><a href="http://lannainfo.library.cmu.ac.th/lannafood/" rel="noreferrer" target="_blank">อาหารพื้นบ้านล้านนา</a></li>
                        <li><a href="http://lannainfo.library.cmu.ac.th/picturelanna/picture_trails.php" rel="noreferrer" target="_blank">ภาพล้านนาในอดีต</a></li>
                        <li><a href="http://lannainfo.library.cmu.ac.th/lanna-music.php" rel="noreferrer" target="_blank">เพลงล้านนา</a></li>
                        <li><a href="http://lannainfo.library.cmu.ac.th/lannatradition/" rel="noreferrer" target="_blank">ประเพณีล้านนา</a></li>
                        <li><a href="http://lannainfo.library.cmu.ac.th/lannalacquerware/" rel="noreferrer" target="_blank">เครื่องเขินล้านนา</a></li>
                        <li><a href="http://lannainfo.library.cmu.ac.th/lannapoetry/" rel="noreferrer" target="_blank">ค่าวล้านนา</a></li>
                        <li><a href="http://lannainfo.library.cmu.ac.th/lannapottery/" rel="noreferrer" target="_blank">เครื่องปั้นดินเผาล้านนา</a></li> */}
                    </ul>
                </div>
                <div className={layout["contact-us"]}>
                    <h5>FOLLOW US</h5>
                    <div className={layout["footer-social"]}>
                        <ul className={layout["social-link"]}>
                            {(init.facebook)?(
                                <li><button data-href={init.facebook} onClick={e=>openLink(e)} aria-label="Facebook" role="link"><FaFacebookF /></button></li>
                            ):''}
                            {(init.line)?(
                                <li><button data-href={init.line} onClick={e=>openLink(e)} aria-label="Line" role="link"><BsLine /></button></li>
                            ):''}
                            {(init.twitter)?(
                                <li><button data-href={init.twitter} onClick={e=>openLink(e)} aria-label="Twitter" role="link"><FaTwitter /></button></li>
                            ):''}
                            {(init.tiktok)?(
                                <li><button data-href={init.tiktok} onClick={e=>openLink(e)} aria-label="Ticktok" role="link"><FaTiktok /></button></li>
                            ):''}
                            {(init.youtube)?(
                                <li><button data-href={init.youtube} onClick={e=>openLink(e)} aria-label="Youtube" role="link"><FaYoutube /></button></li>
                            ):''}
                            {(init.email)?(
                                <li><button data-href={`mailto:`+init.email} onClick={e=>openLink(e)} aria-label="Email" role="link"><FaEnvelope /></button></li>
                            ):''}
                            {(init.phone)?(
                                <li><button data-href={`tel:`+init.phone} onClick={e=>openLink(e)} aria-label="Telephone" role="link" ><FaPhoneAlt /></button></li>
                            ):''}
                        </ul>
                    </div>
                    <h5>{lang("สถิติผู้เข้าชม","VISITOR STATS")}</h5>
                    <ul className={layout["visitors"]}>
                    {visitorCount !== null ? (
                        visitorCount.map((item,key)=>(
                        <li key={key}>
                            {lang(item.title_th,item.title_en)} : <span>{item.total}</span>
                        </li>
                        ))
                    ) : (
                        <p>Loading visitor count...</p>
                    )}
                    </ul>
                </div>
            </div>                 
        </footer>
        :''}
    </ThemeContext.Provider>
  )
}
