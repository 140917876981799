import { lang } from '../lang'

export default function Nav({styles, type_id, article_id,article_name}) {
    return (
        <ul className={styles["nav-menu"]}>
            <li key={'nav-link-'+0}>
                <a href={'/'}>{lang('หน้าแรก','Home','主页')}</a>
            </li>
            <li key={'nav-link-'+1}>
                <a href={'/rsvn'}>{lang('จองเข้าเยี่ยมชม','Reservation','预订')}</a>
            </li>
        </ul>
    )
}
