import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { lang, img_url, gotoURL } from '../lang'
import ThemeContext from '../../context/ThemeContext';
import api from '../api'

SwiperCore.use([Navigation, Pagination]);
export default function Slide({article,classes}) {
    const [images, setImages] = useState([]);
    const { language, page, init } = useContext(ThemeContext);
    SwiperCore.use([Autoplay]); // start autoplay

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                let article_id = 1;
                if(article.id){
                    article_id = article.id;
                }
              const response = await api.get('/gallery/'+article_id);
              setImages(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[article]);

    return (
        <div className={classes["slide-container"]}>
            {/* group items  */}
            <Swiper
                spaceBetween={5}
                slidesPerView={1}
                speed={900}
                autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                pagination={true} 
                modules={[Pagination]} 
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
            >
                {images.map((item, index) => (
                    <SwiperSlide className={classes["swiper-slide"]} key={index}>
                        <div className={classes["card"]}>
                            <img src={img_url + item.url} alt={lang(item.name_th,item.name_en,item.name_cn)}/>
                            <div className={classes["img-effect"]}></div>
                            <div className={classes["info"]}>
                            <h1 className={""}>&nbsp;</h1>
                            <p>{lang(init.open_info_th,init.open_info_en,init.open_info_cn)}</p>
                            <button onClick={e=>gotoURL('/rsvn')}>{lang("จองเข้าเยี่ยมชม","Reservation","预订")}</button>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
